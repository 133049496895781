/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import moment from "moment";
export default {
  formatDate(value: string | null) {
    return moment(String(value)).format("DD-MM-YYYY");
  },
  unFormatDate(value: string | null) {
    return moment(String(value)).format("YYYY-MM-DD");
  },
  formatTime(value: string | null) {
    return moment(String(value)).format("HH:MM");
  },
  getFormattedDate(value: string | null) {
    let formatted: string | null = null;
    if (value && value !== "null" && value !== "") {
      const [year, month, day] = value.split("-");
      formatted = `${day}-${month}-${year}`;
    }
    return formatted;
  },
  getUnformattedDate(value: string | null) {
    let unformatted: string | null = null;
    if (value && value !== "null" && value !== "") {
      const [day, month, year] = value.split("-");
      unformatted = `${year}-${month}-${day}`;
    }
    return unformatted;
  },
  dateToIndonesianLongDate(value: string | null): string | null {
    let unformatted: string | null = null;
    if (value && value !== "null" && value !== "") {
      const listBulan = [
        "",
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];
      const [year, month, day] = value.split("-");
      unformatted = `${Number(day)} ${
        listBulan[Math.abs(Number(month))]
      } ${year}`;
    }
    return unformatted;
  },
  dateToIndonesianLongDateDay(value: string | null): string | null {
    let unformatted: string | null = null;
    if (value && value !== "null" && value !== "") {
      const d = new Date(value);
      const hari = d.getDay();
      const listHari = [
        "Minggu",
        "Senin",
        "Selasa",
        "Rabu",
        "Kamis",
        "Jumat",
        "Sabtu",
      ];
      const dayName = listHari[hari];
      const listBulan = [
        "",
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];
      const [year, month, day] = value.split("-");
      unformatted = `${dayName}, ${Number(day)} ${
        listBulan[Math.abs(Number(month))]
      } ${year}`;
    }
    return unformatted;
  },
  getFormattedDateIndoMonth(value: string | null) {
    let formatted: string | null = null;
    if (value && value !== "null" && value !== "") {
      const listBulan = [
        "",
        "JAN",
        "FEB",
        "MAR",
        "APR",
        "MEI",
        "JUN",
        "JUL",
        "AGU",
        "SEP",
        "OKT",
        "NOV",
        "DES",
      ];
      const [year, month, day] = value.split("-");
      formatted = `${day}/${listBulan[Math.abs(Number(month))]}/${year}`;
    }
    return formatted;
  },
};
